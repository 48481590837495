import { InjectionToken, type Signal } from '@angular/core';

/**
 * Describes a parent component that manages a list of options.
 * Contains properties that the options can inherit.
 */
export interface VmrOptionParentComponent {
  multiple?: boolean | Signal<boolean>;
  disableRipple?: boolean | Signal<boolean>;
  hideSingleSelectionIndicator?: boolean | Signal<boolean>;
}

/**
 * Injection token used to provide the parent component to options.
 */
export const VMR_OPTION_PARENT_COMPONENT = new InjectionToken<VmrOptionParentComponent>(
  'VMR_OPTION_PARENT_COMPONENT',
);