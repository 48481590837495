import { toClassName } from '../helpers';
import { MatPseudoCheckbox, MatRipple } from '@angular/material/core';
import { VMR_OPTION_PARENT_COMPONENT, type VmrOptionParentComponent } from './option-parent';
import {
  inject,
  input,
  computed,
  isSignal,
  Component,
  ElementRef,
  booleanAttribute,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  type Signal
} from '@angular/core';

const parseMaybeSignal = (x: boolean | Signal<boolean> | undefined): boolean => isSignal(x) ? x() : !!x;

/**
 * Single option inside of a `VmrVirtualSelect`, `VmrList`, or `VmrMenu` component.
 */
@Component({
  standalone: true,
  selector: 'vmr-option',
  styleUrl: 'option.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatRipple, MatPseudoCheckbox],
  template: `
    @if (_multiple) {
      <mat-pseudo-checkbox
        aria-hidden="true"
        [disabled]="disabled()"
        [state]="selected() ? 'checked' : 'unchecked'"
      />
    }

    <span class="vmr-option-text">
      <ng-content />
    </span>

    @if (selected() && !_multiple && !_hideSingleSelectionIndicator) {
      <mat-pseudo-checkbox
        state="checked"
        aria-hidden="true"
        appearance="minimal"
        [disabled]="disabled()"
      />
    }

    @if (!_disableRipple) {
      <div
        mat-ripple
        aria-hidden="true"
        class="vmr-option-ripple"
        [matRippleDisabled]="disabled()"
        [matRippleTrigger]="_hostElement"
      ></div>
    }
  `,
  host: {
    'role': 'option',
    '[class]': 'hostClassList()',
    '[class.vmr-option-multiple]': '_multiple'
  },
})
export class VmrOption {
  /** @internal */
  protected _multiple: boolean;

  /** @internal */
  protected _disableRipple: boolean;

  /** @internal */
  protected _hideSingleSelectionIndicator: boolean;

   /** @internal */
  protected _hostElement = inject<ElementRef<HTMLElement>>(ElementRef).nativeElement;

  /** Whether or not the option is currently selected. */
  readonly selected = input(false, { transform: booleanAttribute });

  /** Whether or not the option is currently disabled. */
  readonly disabled = input(false, { transform: booleanAttribute });

  /** @internal */
  readonly hostClassList = computed<string>(() =>
    toClassName(
      'vmr-option vmr-list-item',
      this.disabled() && 'vmr-option-disabled',
      this.selected() && 'vmr-option-selected'
    )
  );

  constructor() {
    const parent = inject<VmrOptionParentComponent>(VMR_OPTION_PARENT_COMPONENT, {optional: true});
    this._multiple = parseMaybeSignal(parent?.multiple);
    this._disableRipple = parseMaybeSignal(parent?.disableRipple);
    this._hideSingleSelectionIndicator = parseMaybeSignal(parent?.hideSingleSelectionIndicator);
  }
}